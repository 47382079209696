<template>
    <v-app light>
        <v-navigation-drawer
                width="250"
                class="pl-1 primary darken-4 grey--text"
                dark style=""
                persistent
                :mini-variant="miniVariant"
                v-model="drawer"
                fixed
                app
        >
            <v-toolbar flat class="transparent" dense>
                <v-list class="pa-0" :class="{'list-border-bottom' : miniVariant}">
                    <v-list-tile>
                        <v-list-tile-content v-if="!miniVariant">
                            <v-list-tile-title> 
                                <router-link :to="{name: 'home'}" @click.native.stop="NavBarChange()"><h3 class="white--text" v-text="appName"></h3></router-link>
                            </v-list-tile-title>
                        </v-list-tile-content>
                        
                        <v-list-tile-action v-if="!miniVariant" :to="home_link">
                            <router-link  :to="{name: 'home'}" @click.native.stop="NavBarChange()"><i class="fas fa-coins fa-2x" color="orange"></i></router-link>
                        </v-list-tile-action>
                        <v-list-tile-action>
                            <v-btn icon @click.stop="miniVariant = !miniVariant">
                                <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
                            </v-btn>
                        </v-list-tile-action>
                    </v-list-tile>
                </v-list>
            </v-toolbar>
            <v-divider></v-divider>

            <v-tooltip right :disabled="!miniVariant">
                <v-toolbar flat class="transparent" dense slot="activator">
                    <v-list class="pa-0" :class="{'list-border-bottom' : miniVariant}">
                        <v-list-tile v-for="item in appFeature" :key="item.icon" :to="item.link" exact>
                            <v-list-tile-action> 
                                <v-icon>{{item.icon}}</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title class="text-xs-left"><b>{{item.name}}</b></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-toolbar>
                <span color="">{{app_feature}}</span>
            </v-tooltip>
            <v-divider></v-divider>

            <v-list subheader :class="{'list-border-bottom' : miniVariant}">
                <v-subheader>Features</v-subheader>
                <template v-for="item in analyticsItems">
                    <v-tooltip right :disabled="!miniVariant">
                        <v-list-tile
                                :key="item.icon"
                                :to="item.link"
                                exact
                                slot="activator"
                        >
                            <v-list-tile-action>
                                <i :class="item.icon" style="font-size: 16px"></i>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title v-text="item.title"></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <span v-text="item.title"></span>
                    </v-tooltip>
                </template>
            </v-list>
            <v-divider></v-divider>

            <v-list subheader>
                <v-subheader>INFORMATION</v-subheader>
                <template v-for="item in developItems">
                    <v-tooltip right :disabled="!miniVariant">
                        <v-list-tile
                                :key="item.icon"
                                :to="item.link"
                                exact
                                slot="activator"
                        >
                            <v-list-tile-action>
                                <v-icon v-html="item.icon"></v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title v-text="item.title"></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <span v-text="item.title"></span>
                    </v-tooltip>
                </template>
            </v-list>
            <!--<v-divider></v-divider>-->
        </v-navigation-drawer>

        <v-toolbar
                app
                flat
                dense
                color="white"  
        >
            <v-toolbar-side-icon
                @click.stop="drawer = !drawer"
                class="hidden-lg-and-up"
                :class="searching ? 'hidden-xs-only' : ''" 
            />
            <v-toolbar-title class="white--text" style="margin-left: 0" >
                <router-link :to="{name: 'home'}" @click.native.stop="NavBarChange()">
                    <v-img
                        :src="require('@/assets/logo.png')"
                        aspect-ratio="2"  
                        contain
                        height="75"
                        width="100"
                        style="cursor: pointer"
                    ></v-img>
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div v-if="$store.state.isAuthenticated">
                <v-tooltip bottom>
                    <v-btn icon class="white--text blue darken-2"  slot="activator"  to="/market_place/shopping_bag/items/my_items">
                        <v-badge color="red" overlap v-if="shoppingBagTotalLength > 0">
                            <span slot="badge" style="font-size: 11px;">{{shoppingBagTotalLength}}</span>
                            <i class="fa-solid fa-shopping-basket" style="font-size: 22px;"> </i>
                        </v-badge>
                        <v-badge color="red" overlap v-else>
                            <i class="fa-solid fa-shopping-basket" style="font-size: 22px;"> </i>
                        </v-badge>
                    </v-btn>
                    <span>{{shoppingBagTotalLength}} items waiting in you shopping bag</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <v-btn icon class="white--text blue darken-2" @click="markNotificationRead()" @click.stop="rightDrawer = !rightDrawer" slot="activator">
                        <v-badge color="red" overlap v-if="$store.state.unread_user_notifications > 0">
                            <span slot="badge" style="font-size: 11px;">{{$store.state.unread_user_notifications}}</span>
                            <!-- <v-icon>notifications</v-icon> -->
                            <i class="fa-solid fa-bell" style="font-size: 22px;"> </i>
                        </v-badge>
                        <v-badge color="red" overlap v-else>
                            <!-- <v-icon>notifications</v-icon> -->
                            <i class="fa-solid fa-bell" style="font-size: 22px;"> </i>
                        </v-badge>
                        
                    </v-btn>
                    <span>{{$store.state.unread_user_notifications}} unread notifications</span>
                </v-tooltip>

                <v-menu>
                    <v-btn icon slot="activator">
                        <v-avatar class="white--text blue darken-2" size="32">
                            <i class="fa-solid fa-user" style="font-size: 22px;"> </i>
                        </v-avatar>
                    </v-btn>
                    <v-list class="pa-0" light>
                        <v-list-tile avatar>
                            <v-list-tile-avatar>
                                <v-avatar class="white--text blue darken-2" size="40px">
                                    <i class="fa-solid fa-user" > </i>
                                </v-avatar>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ $store.state.username }} 🌟</v-list-tile-title>
                                <v-list-tile-sub-title><b>{{ serial_no }}</b></v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider></v-divider>

                        <v-list-tile key="user_credit" class="text-xs-center mt-0 pt-1 pr-0 pb-0">
                            <p class="ma-0 pa-0">
                                <b>Score:</b> {{ user_credit }} <small> Credits</small>
                            </p>
                        </v-list-tile>
                        <v-divider></v-divider>

                        <v-list-tile key="dashboard"  to="/dashboard/info/save">
                            <v-list-tile-action>
                                <i class="fa-solid fa-gauge"></i>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>My Dashboard</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider></v-divider>

                        <v-list-tile key="profile" to="/user_profile/account">
                            <v-list-tile-action>
                                <i class="fa-solid fa-user black--text" style="font-size: 22px;"> </i>
                                <!-- <v-icon class="">person</v-icon> -->
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>My Profile</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider></v-divider>

                        <v-list-tile key="lock_open" @click="logOut()">
                            <v-list-tile-action>
                                <v-icon class="black--text">lock_open</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>Logout</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-menu>
            </div>
            <div v-else id="authenticate_btn" >
                <v-tooltip bottom>
                    <v-btn icon class="white--text blue darken-2"  slot="activator"  to="/market_place/shopping_bag/items/my_items">
                        <v-badge color="red" overlap v-if="shoppingBagTotalLength > 0">
                            <span slot="badge" style="font-size: 11px;">{{shoppingBagTotalLength}}</span>
                            <i class="fa-solid fa-shopping-basket" style="font-size: 20px;"> </i>
                        </v-badge>
                        <v-badge color="red" overlap v-else>
                            <i class="fa-solid fa-shopping-basket" style="font-size: 20px;"> </i>
                        </v-badge>
                    </v-btn>
                    <span>{{shoppingBagTotalLength}} items waiting in you shopping bag</span>
                </v-tooltip>
                <v-btn class="blue--text" icon to="/login" style="font-size: smaller">
                    <i class="fa-solid fa-arrow-right-to-bracket fa-2x"></i>
                    <div >Login</div>
                </v-btn>
                <v-btn class=" red--text " icon to="/signup" style="font-size: smaller">
                    <i class="fa-solid fa-user-plus fa-2x"></i>
                    <div >SignUp</div>
                </v-btn>
            </div>
        </v-toolbar>
        
        <v-progress-linear app v-show="$store.state.isLoading" color="teal" v-bind:indeterminate="true"  class="mt-5 pb-0 mb-0"></v-progress-linear>
        <v-content v-bind:class="{'mt-0 pt-0': $store.state.isLoading}" style="background-color: #ededed">  
            <router-view/>
        </v-content>

        <v-navigation-drawer
                temporary
                :right="right"
                v-model="rightDrawer"
                fixed
                app
        >
            <v-toolbar flat prominent dark  class="white--text blue darken-2">
                <v-toolbar-title>Notifications </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.stop="rightDrawer = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list subheader dense >
                <v-subheader>All Notifications</v-subheader>
                <template v-for="item in $store.state.user_notifications">
                    <v-layout row wrap class="dark--text ma-0 pa-1 pl-2 pr-3" :id="'notification_'+item.id" :key="item.id">
                        <v-flex xs11 text-xs-left class=" ma-0 pa-0">
                            <router-link :to="item.obj_url" style="font-size: smaller; text-decoration: none">
                                <p class="pa-0 ma-0" style="font-size: smaller!important" >
                                    <b>{{item.verb}}: ({{ item.get_date_str }})</b>
                                    <br>
                                    <span class="pa-0 ma-0" v-html="item.description"></span>
                                </p>
                            </router-link>
                        </v-flex>
                        <v-flex xs1 class="text--dark text-xs-left ma-0 pt-0 pb-0 px-0">
                            <v-btn class="mr-4" flat icon small @click="deleteNotification(item.id)">
                                <i class="fas fa-xmark" ></i>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </template>                
            </v-list>
        </v-navigation-drawer>

        <v-bottom-nav
            app
            :active.sync="bottomNav"
            :value="true"
            color="white"
            light
            >
            <v-btn 
                v-for="item in bottomNavBarItems" :key="item.id" :to="item.link"
                class="bottom_nav_button red "
                color="primary darken-2"
                flat :value="item.value"
                @click.native.stop="NavBarChange()" 
            >
                <span>{{item.value}}</span>
                <i :class="item.icon" style="margin-bottom: 5px"></i>
            </v-btn>

        </v-bottom-nav>

        <v-dialog width="500px" v-model="$store.state.dialog"> 
            <v-card> 
                <v-card-title class="headline primary darken-2 white--text" primary-title v-html="$store.state.dialog_title"></v-card-title> 
                <v-card-text  v-html="$store.state.dialog_message"> </v-card-text> 
                <v-divider></v-divider> 
                <v-card-actions> 
                    <v-spacer></v-spacer> 
                    <v-btn color="teal" flat @click="$store.commit('activateDialog', false)" > Close </v-btn> 
                </v-card-actions> 
            </v-card> 
        </v-dialog> 

        <div class="text-xs-center pa-0 ma-0">
            <v-snackbar
            :color="$store.state.snackBarColor" multiLine
            :timeout="$store.state.snackBarTimeOut" 
            :top="y === 'top'"
            :bottom="y === 'bottom'"
            :right="x === 'right'"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
            v-model="$store.state.snackBar" class="pa-0 ma-0"
            >
                <div v-html="$store.state.snackBarMessage"  class="pa-0 ma-0" >

                </div>
                <v-btn class="ma-0" flat color="white" @click.native="$store.commit('activateSnackBar', false)">x</v-btn>
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'VuebaseLayout',

        data() {
            return {
                user_credit: "",
                shopping_bag: {
                    items: []
                },
                appName: process.env.VUE_APP_APP_NAME,
                multiLine: true,
                y: 'top',
                x: null,
                mode: '',
                timeout: 4000,
                
                drawer: true,
                serial_no: '',
                home_link: "/",
                fixed: false,
                bottomNav: 'Savings',
                analyticsItems: [],
                developItems: [],
                bottomNavBarItems: [
                    {
                        icon: 'fas fa-piggy-bank fa-2x',
                        value: 'Savings',
                        link: '/savings'
                    },
                    {
                        icon: 'fas fa-shopping-cart fa-2x',
                        value: 'Market Place',
                        link: '/market_place'
                    },
                    {
                        icon: 'fas fa-hands-helping fa-2x',
                        value: 'Njangi',
                        link: '/njangi'
                    },
                    {
                        icon: 'fas fa-hand-holding-usd fa-2x',
                        value: 'Funding',
                        link: '/funding'
                    },
                    {
                        icon: 'fas fa-chart-line fa-2x',
                        value: 'Monitor',
                        link: '/monitor'
                    },
                ],
                miniVariant: false,
                right: true,
                rightDrawer: false,
                tabs: null,
                menuItems: ['Savings', 'Market Place', 'Njangi', 'Dashboard', 'Profile'],
                searching: false,
                search: '',
                app_feature: "",
                appFeature: [],
            }
        },

        methods: {
            async markNotificationRead(){
                this.$store.commit('setIsLoading', true)
                await axios
                    .get('/api/v1/home/mark/user/notifications/read/')
                    .then(response => {
                        console.log(response.data)
                        this.$store.state.unread_user_notifications = 0  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async deleteNotification(notification_id){
                console.log("deleteNotification")
                let formData = new FormData()
                formData.append('notification_id', notification_id)
                await axios
                    .post('/api/v1/home/delete/user/notifications/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        console.log(response.data)
                        var notification = document.getElementById("notification_"+notification_id)
                        notification.classList.add("delete_diplay")

                        this.$store.commit('setSnackBarMessage', "Notification deleted successfully")
                        this.$store.commit('setSnackBarColor', "teal darken-4")
                        this.$store.commit('activateSnackBar', true)

                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },

            async getUserNotifications() {
                this.$store.commit('setIsLoading', true)
                await axios
                    .get('/api/v1/home/get/user/notifications/')
                    .then(response => {
                        this.$store.state.user_notifications = response.data[0]  // get the data and fill into campaigns
                        this.$store.state.unread_user_notifications = response.data[1]  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getUserInfo() {
                this.$store.commit('setIsLoading', true)
                if (this.$store.state.isAuthenticated && !this.$store.state.user_info_state){
                    await axios
                        .get('/api/v1/home/get/user/info/')
                        .then(response => {
                            this.user_credit = response.data[0]["user_credit"][0]["credit"]
                            this.$store.commit('setUser', response.data[0]["user"])
                            this.$store.commit('setUserState', response.data[0]["user_info"])
                            this.$store.commit('setUserInfo', response.data[0])
                            this.$store.commit('setUserName', response.data[0]["user"]["username"])

                            this.serial_no = this.$store.state.user_info.user_profile.serial_no
                        })
                        .catch(error => {
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        })
                }
                this.$store.commit('setIsLoading', false)
            },

            logOut() {
                axios.defaults.headers.common['Authorization'] = ""
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                localStorage.removeItem('userid')
                localStorage.removeItem('shopping_bag')
                this.$store.commit('removeToken')
                this.$store.state.username = "" 
                this.$store.state.user_info_state = false
                this.$store.state.user_info = []
                this.$store.state.user_notifications = []
                this.$store.state.unread_user_notifications = 0
                              
                var navigation = this.$router
                this.$store.commit('setSnackBarMessage', "You have logged out successfully")
                this.$store.commit('setSnackBarColor', "teal darken-4")
                this.$store.commit('activateSnackBar', true)
                let router = this.$router
                router.push('/')

            },

            NavBarChange() {
                var current_url = window.location.href;
                if(this.$store.state.isAuthenticated)
                    if(current_url.search("/faq") === -1){
                        if(current_url.search("/savings") !== -1){
                                this.analyticsItems = [
                                {
                                        icon: 'fas fa-money-check fa-2x',
                                        title: 'My Campaigns',
                                        link: '/savings/campaigns/active'
                                    },
                                    {
                                        icon: 'fas fa-hand-holding-usd fa-2x',
                                        title: 'My Loans',
                                        link: '/savings/loans/active'
                                    },
                                    {
                                        icon: 'fas fa-exchange fa-2x',
                                        title: 'My Transactions',
                                        link: '/savings/transactions/all'
                                    },
                                    {
                                        icon: 'fas fa-cash-register fa-2x',
                                        title: 'My Withdrawals',
                                        link: '/savings/withdrawals/request'
                                    }
                                ];
                                this.developItems = [
                                    {
                                        icon: 'fas fa-address-card fa-2x',
                                        title: 'Contact Us',
                                        link: '/savings/contact_us/all'
                                    },
                                    {
                                        icon: 'fas fa-file-contract fa-2x',
                                        title: 'Legal Bindings',
                                        link: '/savings/legal_binding/all'
                                    },
                                    {
                                        icon: 'fas fa-question-circle fa-2x',
                                        title: 'FAQs',
                                        link: '/faqs/types/savings'
                                    },
                                    {
                                        icon: 'fa-solid fa-handshake fa-2x',
                                        title: 'Become a Partner',
                                        link: '/become_partner'
                                    },
                                ];
                                this.appFeature = [
                                    {
                                        icon: 'fas fa-piggy-bank fa-2x',
                                        name: 'Savings',
                                        link: '/savings'
                                    },
                                ];
                                this.app_feature = "Savings";
                        }
                        else if(current_url.search("/market_place") !== -1){
                                
                                this.analyticsItems = [
                                    {
                                        icon: 'fas fa-store-alt fa-2x',
                                        title: 'Market Items',
                                        link: '/market_place/items/types/all'
                                    },
                                    {
                                        icon: 'fas fa-shopping-basket fa-2x',
                                        title: 'Shopping Bag',
                                        link: '/market_place/shopping_bag/items/my_items'
                                    },
                                    {
                                        icon: 'fas fa-history fa-2x',
                                        title: 'Shopping History',
                                        link: '/market_place/history/items/types/favorite'
                                    }
                                ]
                                this.developItems = [
                                    {
                                        icon: 'fas fa-address-card fa-2x',
                                        title: 'Contact Us',
                                        link: '/market_place/contact_us/all'
                                    },
                                    {
                                        icon: 'fas fa-file-contract fa-2x',
                                        title: 'Legal Bindings',
                                        link: '/market_place/legal_binding/all'
                                    },
                                    {
                                        icon: 'fas fa-question-circle fa-2x',
                                        title: 'FAQs',
                                        link: '/faqs/types/market_place'
                                    },
                                    {
                                        icon: 'fa-solid fa-handshake fa-2x',
                                        title: 'Become a Partner',
                                        link: '/become_partner'
                                    },
                                ]
                                this.appFeature = [
                                    {
                                        icon: 'fas fa-shopping-cart fa-2x',
                                        name: 'Market Place',
                                        link: '/market_place'
                                    },
                                ];
                                this.app_feature = "Market Place"
                        }
                        else if(current_url.search("/njangi") !== -1){
                                this.analyticsItems = [
                                    {
                                        icon: 'fas fa-users fa-2x',
                                        title: 'Groups',
                                        link: '/njangi/groups/types/Active'
                                    },
                                    {
                                        icon: 'fas fa-calendar-alt fa-2x',
                                        title: 'Events',
                                        link: '/njangi/events/types/Active'
                                    },
                                    {
                                        icon: 'fas fa-cash-register fa-2x',
                                        title: 'Withdrawals',
                                        link: '/njangi/withdrawals/request'
                                    }
                                ]
                                this.developItems = [
                                    {
                                        icon: 'fas fa-address-card fa-2x',
                                        title: 'Contact Us',
                                        link: '/njangi/contact_us/all'
                                    },
                                    {
                                        icon: 'fas fa-file-contract fa-2x',
                                        title: 'Legal Bindings',
                                        link: '/njangi/legal_binding/all'
                                    },
                                    {
                                        icon: 'fas fa-question-circle fa-2x',
                                        title: 'FAQs',
                                        link: '/faqs/types/njangi'
                                    },
                                    {
                                        icon: 'fa-solid fa-handshake fa-2x',
                                        title: 'Become a Partner',
                                        link: '/become_partner'
                                    },
                                ]
                                this.appFeature = [
                                    {
                                        icon: 'fas fa-hands-helping fa-2x',
                                        name: 'Njangi',
                                        link: '/njangi'
                                    },
                                ];
                                this.app_feature = "Njangi"
                        }
                        else if(current_url.search("/funding") !== -1){
                                this.analyticsItems = [
                                    {
                                        icon: 'fas fa-project-diagram fa-2x',
                                        title: 'Projects',
                                        link: '/funding/projects/types/active'
                                    },
                                    {
                                        icon: 'fas fa-calendar-alt fa-2x',
                                        title: 'Events',
                                        link: '/funding/events/types/active'
                                    }
                                ]
                                this.developItems = [
                                    {
                                        icon: 'fas fa-address-card fa-2x',
                                        title: 'Contact Us',
                                        link: '/funding/contact_us/all'
                                    },
                                    {
                                        icon: 'fas fa-file-contract fa-2x',
                                        title: 'Legal Bindings',
                                        link: '/funding/legal_binding/all'
                                    },
                                    {
                                        icon: 'fas fa-question-circle fa-2x',
                                        title: 'FAQs',
                                        link: '/faqs/types/funding'
                                    },
                                    {
                                        icon: 'fa-solid fa-handshake fa-2x',
                                        title: 'Become a Partner',
                                        link: '/become_partner'
                                    },
                                ]
                                this.appFeature = [
                                    {
                                        icon: 'fas fa-hand-holding-usd fa-2x',
                                        name: 'Crowd Funding',
                                        link: '/funding'
                                    },
                                ];
                                this.app_feature = "Crowd Funding"
                        }
                        else if(current_url.search("/monitor") !== -1){
                                this.analyticsItems = [
                                    {
                                        icon: 'fas fa-columns fa-2x',
                                        title: 'My Dashboard',
                                        link: '/monitor/dashboard/overall'
                                    },
                                    {
                                        icon: 'fas fa-folder-minus fa-2x',
                                        title: 'My Expenses',
                                        link: '/monitor/expense/active'
                                    },
                                    {
                                        icon: 'fas fa-folder-plus fa-2x',
                                        title: 'My Income',
                                        link: '/monitor/income/active'
                                    }
                                ]
                                this.developItems = [
                                    {
                                        icon: 'fas fa-address-card fa-2x',
                                        title: 'Contact Us',
                                        link: '/monitor/contact_us/all'
                                    },
                                    {
                                        icon: 'fas fa-file-contract fa-2x',
                                        title: 'Legal Bindings',
                                        link: '/monitor/legal_binding/all'
                                    },
                                    {
                                        icon: 'fas fa-question-circle fa-2x',
                                        title: 'FAQs',
                                        link: '/faqs/types/monitor'
                                    },
                                    {
                                        icon: 'fa-solid fa-handshake fa-2x',
                                        title: 'Become a Partner',
                                        link: '/become_partner'
                                    },
                                ]
                                this.appFeature = [
                                    {
                                        icon: 'fas fa-chart-line fa-2x',
                                        name: 'Monitor',
                                        link: '/monitor'
                                    },
                                ];
                                this.app_feature = "Monitor"
                        }
                        else{
                            this.analyticsItems = [
                                {
                                    icon: 'fa-solid fa-gauge fa-2x',
                                    title: 'Dashboard',
                                    link: '/dashboard/info/save'
                                },
                                {
                                    icon: 'fa-solid fa-user fa-2x',
                                    title: 'My Profile',
                                    link: '/user_profile/account'
                                },
                                {
                                    icon: 'fa-solid fa-globe fa-2x',
                                    title: 'Products & Services',
                                    link: '/product_service'
                                },
                            ]
                            this.developItems = [
                                {
                                    icon: 'fas fa-address-card fa-2x',
                                    title: 'Contact Us',
                                    link: '/contact_us'
                                },
                                {
                                    icon: 'fa-solid fa-people-group fa-2x',
                                    title: 'Our Team',
                                    link: '/our_team'
                                },
                                {
                                    icon: 'fa-solid fa-info fa-2x',
                                    title: 'About US',
                                    link: '/about_us'
                                },
                                {
                                    icon: 'fa-solid fa-handshake fa-2x',
                                    title: 'Become a Partner',
                                    link: '/become_partner'
                                }
                            ]
                            this.appFeature = [
                                {
                                    icon: 'fas fa-home fa-2x',
                                    name: 'Home',
                                    link: '/'
                                },
                            ];
                            this.app_feature = "Home"
                        }
                    }
                if (!this.$store.state.isAuthenticated){
                    this.analyticsItems = [
                        {
                            icon: 'fa-solid fa-arrow-right-to-bracket fa-2x',
                            title: 'Log In',
                            link: '/login'
                        },
                        {
                            icon: 'fa-solid fa-user-plus fa-2x',
                            title: 'Create Account',
                            link: '/signup'
                        },
                        {
                            icon: 'fa-solid fa-globe fa-2x',
                            title: 'Products & Services',
                            link: '/product_service'
                        },
                    ]
                    this.developItems = [
                        {
                            icon: 'fas fa-address-card fa-2x',
                            title: 'Contact Us',
                            link: '/contact_us'
                        },
                        {
                            icon: 'fa-solid fa-people-group fa-2x',
                            title: 'Our Team',
                            link: '/our_team'
                        },
                        {
                            icon: 'fa-solid fa-info fa-2x',
                            title: 'About US',
                            link: '/about_us'
                        },
                        {
                            icon: 'fa-solid fa-handshake fa-2x',
                            title: 'Become a Partner',
                            link: '/become_partner'
                        }
                    ]
                    this.appFeature = [
                        {
                            icon: 'fas fa-home fa-2x',
                            name: 'Home',
                            link: '/'
                        },
                    ]
                    this.app_feature = "Home"
                }
                this.$store.commit('setAppFeature', this.app_feature)
            },
        
            onBlur() {
                this.searching = false
                this.search = ''
            },

            searchBegin() {
                this.searching = true
                setTimeout(() => document.querySelector('#search').focus(), 100)
            },

            searchEnd() {
                this.searching = false
                this.search = ''
                document.querySelector('#search').blur()
            }
        },

        mounted(){
            if (this.$store.state.isAuthenticated){
                this.getUserInfo()
                this.getUserNotifications() 
                this.$store.commit('setUnreadUserNotifications')
            }
            this.NavBarChange() 
            console.log(this.$store.state.user_notifications)
            this.shopping_bag = this.$store.state.shopping_bag
        },

        computed: {
            shoppingBagTotalLength(){
                let totalLength = 0
                for(let i=0; i < this.shopping_bag.items.length; i++){
                    totalLength += this.shopping_bag.items[i].quantity
                }
                return totalLength
            }
        },

        // this will change the values of the navebar each time there is a url change
        watch: {
            '$route': function(to, from) {
                this.NavBarChange();
                this.getUserInfo()
            }
        },
      }
</script>

<style >
    div#authenticate_btn .v-btn .v-btn__content{
        display: flex;
        flex-direction: column;
    }
    .v-snack--multi-line .v-snack__content{
        padding: 10px!important;
        
    }
    .v-toolbar__title {
        font-size: 18px!important;
    }

</style>

<style scoped lang="stylus">
    @import '../../node_modules/vuetify/src/stylus/settings/_variables.styl';
    
    .delete_diplay{
        display: none;
    }    
    .right-nav-tile-action {
        max-width: 25px!important;
        min-width: 25px!important;
    }

    .bottom_nav_button {
        padding: 0!important;
        min-width: 0!important;
    }

    .bottom-menu {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    .searching {
        overflow: hidden;
        width: 208px;
        padding-left: 8px;
        transition: $primary-transition;
    }

    .searching--closed {
        padding-left: 0;
        width: 0;
    }

    .searching > * {
        right: 8px;
    }

    .searching--closed > * {
        display: none;
    }

    .hidden-searching {
        @media $display-breakpoints.sm-and-down {
            display: none !important;
        }
    }

    .list-border-bottom {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
</style>
