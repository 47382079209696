import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user_notifications: [],
        username: "",
        close_url: "/",
        save_item: "",
        current_app_feature: "",
        user_info_state: false,
        user_info: {'user': {}, },
        unread_user_notifications: 0,
        isAuthenticated: false,
        lengthActiveCampaigns: 0,
        lengthActiveLoans: 0,
        token: '',
        isLoading: false,
        snackBar: false,
        snackBarColor: "",
        snackBarMessage: "",
        snackBarTimeOut: 4000,
        acceptedNjangiGroup: false,
        acceptedNjangiGroupId: '',
        acceptedNjangiEvent: false,
        acceptedNjangiEventId: '',
        MerchantCodeMTN: '024781',
        MerchantCodeOrange: '778810',
        acceptedNjangiEventId: '',
        dialog: false,
        dialog_title: "",
        dialog_message: "",
        shopping_bag: {
            items: [],
        },
    },
    mutations: {
        initialiseStore(state){
            if(localStorage.getItem('token')){
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
            }else{
                state.token = ''
                state.isAuthenticated = false

            }
            if(localStorage.getItem('shopping_bag')){
                state.shopping_bag = JSON.parse(localStorage.getItem('shopping_bag'))
            }else{
                localStorage.setItem('shopping_bag', JSON.stringify(state.shopping_bag))
            }
        }, 

        addToShoppingBag(state, item){
            const exists = state.shopping_bag.items.filter(i => i.product.id === item.product.id)
            if (exists.length){
                exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
            }else{
                state.shopping_bag.items.push(item)
            }
            localStorage.setItem('shopping_bag', JSON.stringify(state.shopping_bag))
        },
        resetShoppingBag(state){
            state.shopping_bag = {
                items: [],
            }
            localStorage.setItem('shopping_bag', JSON.stringify(state.shopping_bag))
        },
        setToken(state, token){
            state.token = token  
            state.isAuthenticated = true
        },
        setUserName(state, username){
            state.username = username  
        },
        setUser(state, user){
            state.user = user  
        },
        setUserState(state, user_state){
            state.user_info_state = user_state  
        },
        setUserInfo(state, user_info){
            state.user_info = user_info  
        },

        setAppFeature(state, feature){
            state.current_app_feature = feature  
        },
        removeToken(state, token){
            state.token = "" 
            state.isAuthenticated = false
        },
        setIsLoading(state, status){
            state.isLoading = status  // change the loading bar
        },
        setLengthActiveCampaigns(state, length){
            state.lengthActiveCampaigns = length  
        },
        setLengthActiveLoans(state, length){
            state.lengthActiveLoans = length  
        },
        setCloseURL(state, url){
            state.close_url = url  
        },
        setSaveItem(state, item_id){
            state.save_item = item_id  
        },
        acceptedNjangiGroupInvitation(state, group_id){
            state.acceptedNjangiGroup = true
            state.acceptedNjangiGroupId = group_id
        },
        acceptedNjangiEventInvitation(state, event_id){
            state.acceptedNjangiEvent = true
            state.acceptedNjangiEventId = event_id
        },
        clearNjangiGroupInvitation(state){
            state.acceptedNjangiGroup = false
            state.acceptedNjangiGroupId = ''
        },
        clearNjangiEventInvitation(state){
            state.acceptedNjangiEvent = false
            state.acceptedNjangiEventId = ''
        },
        activateDialog(state, status){
            state.dialog = status  
        },
        setDialogTitle(state, title){
            state.dialog_title = title  
        },
        setDialogMessage(state, message){
            state.dialog_message = message  
        },
        activateSnackBar(state, status){
            state.snackBar = status  
        },
        setSnackBarMessage(state, message){
            state.snackBarMessage = message  
        },
        setSnackBarColor(state, color){
            state.snackBarColor = color  
        },
        setSnackBarTimeOut(state, timeout){
            state.snackBarTimeOut = timeout  
        },
        setUserNotifications(state){
            axios
                .get('/api/v1/home/get/user/notifications/')
                .then(response => {
                    state.user_notifications = response.data  // get the data and fill into campaigns
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setUnreadUserNotifications(state){
            var no_unread_notifications = 0
            for (var notification in state.user_notifications){
                if(notification.read){
                    no_unread_notifications = no_unread_notifications + 1
                }
            }
            state.unread_user_notifications = no_unread_notifications
        },
    },
    actions: {}
})
