
<template>
    <component :is="component">
        <slot/>
    </component>
</template>

<script>
import axios from 'axios'
    //https://vuetify-v1-doc.netlify.app/#/vuetify/quick-start
    import VuebaseLayout from './layouts/VuebaseLayout'

    export default {
        name: 'App',

        components: {
            VuebaseLayout
        },
        data(){
            return {
                shopping_bag: {
                    items: []

                }
            }
        },

        beforeCreate(){
            this.$store.commit('initialiseStore')

            const token = this.$store.state.token
            if (token){
                axios.defaults.headers.common['Authorization'] = "Token " + token
            } else {
                axios.defaults.headers.common['Authorization'] = ""
            }
        },

        computed: {
            component() {
                return VuebaseLayout
            }
        }
    }
</script>

<style>
    .v-toolbar__content{
        padding-left: 10px!important;  
        padding-right: 0!important;
    }
    .page_title>.v-toolbar__content>:first-child {
        width: 100%!important;
    }
</style>
