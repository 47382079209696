import Vue from 'vue'
import './plugins'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas);
library.add(fab);

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "https://api.pettycashglobal.com"
console.log(process.env.VUE_APP_API_URL)

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
} 


Vue.filter('truncate', filter);  // creating filters in vue for truncating text
new Vue({
    router,
    store,
    axios,
    render: h => h(App)
}).$mount('#app')
